<template>
  <form @change="changes" v-if="datos">
      <div>
      <button @click.prevent="eliminar">Eliminar</button>
            <label>Nº Factura</label>
            <input type="text" name="ID" v-model="datos.ID">
      </div>
      <div>
            <label>Obra</label>
            <input type="text" name="OBRA" v-model="datos.OBRA">
      </div>
      <div>
            <label>Fecha Factura</label>
            <input type="date" name="FECHA_FACTURA" v-model="datos.FECHA_FACTURA">
      </div>
      <div>
            <label>Fecha Cobro</label>
            <input type="date" name="FECHA_COBRO" v-model="datos.FECHA_COBRO">
      </div>
      <div>
            <label>Importe</label>
            <input type="number" name="IMPORTE" v-model="datos.IMPORTE"> 
      </div>
  </form>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
    name: 'DetalleFacturaGeo',
    data: function () {
        return {
            tabla: 'facturas_geo',
            id: this.$route.params.id,
            datos: null
        }
    },
    computed: {
        ...mapState(['tablas','cache'])
    },
    methods:{
        ...mapActions(['api','cargartabla']),
        changes(event,object){
            let campo = null
            let valor = null
            if(event!==null && event.target!==undefined){
                campo = event.target.name
                valor = event.target.value
            }else{
                campo = object[0]
                valor = object[1]
                if(valor){
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = this.listas[campo.toLowerCase()].filter(function (e){
                        return e['code'] === valor
                    })[0]['label']
                }else{
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = null
                }
            }
            let id = this.$route.params.id
            this.api([
                {'campo':'field','valor':campo},
                {'campo':'value','valor':valor},
                {'campo':'ID','valor':id},
                {'campo':'detalle','valor':this.tabla},
                'guardardetalle'
            ])
        },
        async eliminar(){
            const res = await this.api([
                {campo:'tabla',valor:'facturas_geo'},
                {campo:'ID',valor:this.id},
                'eliminar'
            ])
            this.$router.push('/facturas')
        }
    },
    async beforeMount(){
        if(!this.tablas[this.tabla]){
            await this.cargartabla([this.tabla,this.tabla])
        }
        const id = this.id
        this.datos = this.tablas[this.tabla].filter(
            function(e) {
                if(e['ID']){
                    return e['ID']===id
                }
            }
        )[0]
        if(!this.datos){
            this.$router.push('/'+this.tabla)
        }
    }
}
</script>

<style scoped>
form{
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5%;
    margin: 2%;
}
label{ 
    display: block;
}
</style>